import React from "react"
import { navigate } from "gatsby"

import SEO from "../components/seo"
import withState from "../util/withState"

const Logout = props => {
  const { state } = props

  React.useEffect(() => {
    const { doLogout } = state
    doLogout()
    if (typeof window !== "undefined") {
      navigate("/login")
    }
  }, [state])

  return (
    <>
      <SEO title="Logout" keywords={[`mazrui`, `careers`]} />
    </>
  )
}

export default withState(Logout)
